import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/sidebar";
import Chat from "../../components/chat";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { useChat } from "ai/react";
import axios from "axios";
import { io } from "socket.io-client";

const ChatWraper = ({ user, bot, org, setShowAlert, support }) => {
  const { botId } = useParams();
  const [conversations, setConversations] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const conversationIdRef = useRef(conversationId);  // Add this ref
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const messagesRef = useRef([]);
  const [socket, setSocket] = useState(null);
  const [room, setRoom] = useState(botId);
  const [isManual, setIsManual] = useState();
  const textareaRef = useRef(null);
  const [userId] = useState(() => {
    if (user?.sub) {
      return user.sub;
    } else {
      const storedGuestId = localStorage.getItem("guest_id");
      if (storedGuestId) {
        return storedGuestId; 
      } else {
        const newGuestId = `guest-${nanoid(5)}`;
        localStorage.setItem("guest_id", newGuestId);
        return newGuestId;
      }
    }
  });
  const {
    messages,
    setMessages,
    input,
    setInput,
    isLoading,
    handleSubmit,
    handleInputChange,
    reload,
    stop,
  } = useChat({
    api: `${process.env.REACT_APP_CHAT_API}/api/chat`,
    headers: {
      "Content-Type": "application/json",
    },
    body: {
      config: bot?.modelConfig,
      datasource: botId,
    },
    streamMode: bot?.name === "OpenAI chatbot with database reader" ? 'text' : "stream-data",
    onError: (error) => {
      let errorMessage = "An unknown error occurred.";
    
      if (error.message) {
        if (typeof error.message === "string") {
          try {
            // Check if the error message is valid JSON
            const parsedError = JSON.parse(error.message);
            errorMessage = parsedError.detail || parsedError.error || errorMessage;
          } catch (e) {
            // If JSON parsing fails, treat it as plain text
            errorMessage = error.message;
          }
        } else if (
          typeof error.message === "object" &&
          error.message !== null
        ) {
          errorMessage = error.message.detail || JSON.stringify(error.message);
        }
      } else if (typeof error === "string") {
        try {
          // Check if the error is valid JSON
          const parsedError = JSON.parse(error);
          errorMessage = parsedError.detail || parsedError.error || errorMessage;
        } catch (e) {
          // If JSON parsing fails, treat it as plain text
          errorMessage = error;
        }
      } else if (typeof error === "object" && error !== null) {
        errorMessage = error.detail || JSON.stringify(error);
      }
    
      console.log(error, "This is error");
      setErrorMessage(errorMessage);
      setIsError(true);
    },
    onFinish: () => {
      if(userId) {
        handleSendMessage(messagesRef.current);
        sendMessage(conversationId);
      }
      textareaRef.current.focus();
    },
  });

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_ADMIN_API_URL);
    setSocket(newSocket);

    // Join the room when the socket connects
    newSocket.on('connect', () => {
      newSocket.emit('joinRoom', org);
    });

    // Listen for messages from the server
    newSocket.on('message', (data) => {
      // alert(`Message received: ${data}`); // Access the content of the message
      // console.log(conversationIdRef.current, "This is the conversation id in the socket");
      getBotChats();
      if(data === conversationIdRef.current) getConversation(conversationIdRef.current);
      if(support) setShowAlert(true);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [org]);

  useEffect(() => {
    conversationIdRef.current = conversationId;  // Sync the ref with the state
  }, [conversationId]);

  const sendMessage = (message) => {
    if (socket) {
      console.log(`Sending message: ${message} to room: ${org}`);
      socket.emit('message', { room: org, message }); // Include room and message content
    }
  };

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (userId) {
      getBotChats();
    }
  }, [userId]);

  const getBotChats = async () => {
    await axios
      .get(`${process.env.REACT_APP_CHAT_API}/api/chat/get_chat_history/${userId}/${botId}`)
      .then((res) => {
        // console.log(res, "This is all chat");
        setConversations(res.data.chats);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getConversation = async (currentConversationId) => {
    await axios
      .get(`${process.env.REACT_APP_CHAT_API}/api/chat/get_conversation/${currentConversationId}`)
      .then((res) => {
        // console.log(res, "This is conversation");
        setMessages(res.data.conversation.session);
        setIsManual(res?.data?.conversation?.manual);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleSendMessage = async (messages) => {
    const sessionData = {
        session: { messages },
    };
    const response = await axios.post(`${process.env.REACT_APP_CHAT_API}/api/chat/save_chat/${userId}/${botId}`, sessionData, {
        params: { conversation_id: conversationId }
    });
    console.log(response, "This is save response")
    if (!conversationId) {
      setConversationId(response.data.conversation_id);
    }
    getBotChats();
  };

  const manualChatSave = async (e) => {
    e.preventDefault();
    if (input.length > 0) {
      const customObj = {
        id: nanoid(7),
        content: input,
        role: "user",
        createdAt: new Date(),
      };

      const customMessages = [
        ...messages,
        customObj
      ]

      if(userId) {
        await handleSendMessage(customMessages);
        sendMessage(conversationId);
      }

      setInput('');
    }
  };

  return (
    <main className={`flex w-screen box-border ${support ? "h-[100vh]" : "h-screen"}`}>
      <Sidebar user={user} bot={bot} conversations={conversations} messages={messages} setMessages={setMessages} conversationId={conversationId} setConversationId={setConversationId} setIsManual={setIsManual} />
      <Chat user={user} bot={bot} conversations={conversations} messages={messages} setMessages={setMessages} conversationId={conversationId} isError={isError} errorMessage={errorMessage} setIsError={setIsError} setErrorMessage={setErrorMessage} input={input} setInput={setInput} handleSubmit={handleSubmit} handleInputChange={handleInputChange} isLoading={isLoading} reload={reload} stop={stop} isManual={isManual} manualChatSave={manualChatSave} textareaRef={textareaRef} support={support} />
    </main>
  );
};

export default ChatWraper;
