import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import Chat from "../../components/chat";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChatWraper from "../chat-wrapper";
import AudioChat from "../audio-chat";
import DayOneChat from "../dayone-chat";
import { Loader2 } from "lucide-react";
import { Loading } from "../../components/ui/loader";
import { useLogto } from "@logto/react";
import BotStreamsWrapper from "../bot-streams-wrapper";

const Bot = ({ loading, setLoading, token, user, status, setShowAlert, support }) => {
  const { botId } = useParams();
  const location = useLocation();
  const [bot, setBot] = useState();
  const [forbidden, setForbidden] = useState(false);
  const { getAccessToken, signOut, clearAllTokens } = useLogto();
  const params = new URLSearchParams(location.search);
  const roleId = params.get('view');
  const navigate = useNavigate();
  const [org, setOrg] = useState();
  const [signedImg, setSignedImg] = useState();

  useEffect(() => {
    setLoading(true);
    if(status){
      getPublicBot();
    }else{
      if(roleId && user){
        assignRole();
      }else if(!roleId){
        getBot();
      }
    }
  }, [status, user, roleId])

  
  useEffect(() => {
    if (bot?.avatar) {
      // Check if parsedCode.avatar is a valid URL
      const isValidUrl = (string) => {
        try {
          new URL(string);
          return true;
        } catch (_) {
          return false;
        }
      };

      if (isValidUrl(bot.avatar)) {
        // If it's a valid URL, set signedImg directly
        setSignedImg(bot.avatar);
      } else {
        // If it's a path, call getSignedURL
        getSignedURL(bot.avatar).then((url) => {
          if (url) {
            setSignedImg(url); // Set the signed image URL
          }
        });
      }
    }
  }, [bot]);

  const getToken = async () => {
    const org = localStorage.getItem('org');
    if (org === null || org === undefined || org === 'undefined'|| org === 'null') {
      const accessToken = await getAccessToken(process.env.REACT_APP_ADMIN_API_URL);
      return accessToken;
    } else {
      const accessToken = await getAccessToken(process.env.REACT_APP_ADMIN_API_URL, org);
      return accessToken;
    }
  }

  const assignRole = async () => {
    setLoading(true);
    const accessToken = await getToken();
    axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/organizations/assign-role`, {
        user_id: user?.sub,
        email: user?.email,
        bot_id: botId,
        role: "viewer",
        role_id: roleId
      }, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((res) => {
        console.log(res, "This is assign role");
        setLoading(false);
        navigate(`/${botId}`);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getBot = async () => {
    setLoading(true);
    const accessToken = await getToken();
    axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/bot/${botId}`, {headers: {Authorization: `Bearer ${accessToken}`}})
    .then((res) => {
      console.log(res,"res")
      setBot(res.data.data)
      getBotOrg();
      setLoading(false);
    })
    .catch((error) => {
      console.log(error,"error")
      if(error.response.data.statusCode === 403){
        setForbidden(true)
      }
      else{
        clearAllTokens();
        signOut(process.env.REACT_APP_CALLBACK_URL);
        localStorage.removeItem('org')
      }
      setLoading(false);
    })
  }

  const getPublicBot = () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/bot/public/${botId}`)
    .then((res) => {
      console.log(res,"res")
      setBot(res.data.data)
      getBotOrg();
      setLoading(false);
    })
    .catch((error) => {
      console.log(error,"error")
      if(error.response.data.statusCode === 403){
        setForbidden(true)
      }
      setLoading(false);
    })
  }

  const getBotOrg = () => {
    axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/bot/get-org/${botId}`)
    .then((res) => {
      // console.log(res, "This is bots org chat");
      setOrg(res.data.data)
      setBot(prevBot => ({
        ...prevBot,
        org_id: res.data.data
      }));
    })
    .catch((error) => {
      console.log(error, "error");
    });
  }

  const getSignedURL = async (path) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/bot/get-signed-url`, {
        path: path
      });
      setSignedImg(response.data.url); // Ensure this returns the expected URL
    } catch (error) {
      console.error('Error fetching signed URL:', error);
      return null; // Return null or handle the error appropriately
    }
  };

  if(forbidden){
    return(
        <div className="h-screen flex items-center justify-center text-center p-4">
          <h1 className="text-lg font-bold">You don't have access to this bot. Please contact your admin!</h1>
        </div>
    )
  }

  if(loading) return (
    <div className="h-screen flex items-center justify-center text-center">
      <div className="w-full h-screen max-h-full flex items-center justify-center text-sm text-muted-foreground">
        <Loader2 className="size-8 animate-spin" />
      </div>
    </div>
  )

  return (
    <>
      {bot && (
        bot.theme === 'audio' ? <AudioChat bot={bot} user={user} org={org} setShowAlert={setShowAlert} support={support} signedImg={signedImg} /> :
        bot.theme === 'dayone' ? <DayOneChat bot={bot} user={user} org={org} setShowAlert={setShowAlert} support={support} signedImg={signedImg} /> :
        bot.theme === 'simple' ? <ChatWraper bot={bot} user={user} org={org} setShowAlert={setShowAlert} support={support} signedImg={signedImg} /> :
        <BotStreamsWrapper bot={bot} user={user} org={org} setShowAlert={setShowAlert} support={support} />
      )}
    </>
  );
};

export default Bot;
